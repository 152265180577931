import { useState, useEffect } from 'react';
import { ref, get, onValue } from 'firebase/database';
import { db, auth } from './firebase';
import './assets/css/RefData.css'; // Import a CSS file for styling

function RefData() {
  const [data, setData] = useState([]);

  // Define a function to fetch the data from Firebase
  const fetchData = async () => {
    const dbRef = ref(db, 'refs');
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      setData(snapshot.val());
    }
  };

  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <h2>Database Entries</h2>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Referrer</th>
            <th>Minter</th>
            <th>Amount</th>
            <th>Polyscan</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((key) => (
            <tr key={key}>
              <td>{data[key].Date}</td>
              <td>{data[key].Referrer}</td>
              <td>{data[key].Minter}</td>
              <td>{data[key].Amount}</td>
              <td>{data[key].Polyscan}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RefData;
