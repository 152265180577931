import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { useContext } from 'react';
import { Container, Row } from "react-grid-system";

import "./assets/css/Modal.css";
import "./assets/css/Mint.css";

//fonts
import "./assets/fonts/ClashDisplay-Semibold.otf";
import "./assets/fonts/MazzardM-Medium.ttf";
import "./assets/fonts/StyreneBLC-Regular.otf";

import centerImage from "./assets/images/Careless_LFG_GIF.gif";
import matic from "./assets/images/matic.png";

// social
import twitterIcon from "./assets/images/twitter-fill.png";
import discordIcon from "./assets/images/discord-fill.png";
import boatIcon from "./assets/images/boat.png";

// CONNECT/DISCONNECT
import Web3Modal from "web3modal";
import {ethers} from "ethers";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

import { db, auth } from './firebase';
import { get, set, ref, onValue, update, increment } from "firebase/database";

const { REACT_APP_INFURA_API } = process.env;

const providerOptions = {
  injected: {
    display: {
      name: 'Metamask/Phantom',
      description: 'Connect with the provider in your Browser',
      infuraId: REACT_APP_INFURA_API,
      supportedChainIds: ['0x89'],
      chainId: '0x89',
    },
    package: null,
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, 
    options: {
      appName: "Careless Alien Club Minter",
      infuraId: process.env.REACT_APP_INFURA_API,
      supportedChainIds: ['0x89'],
      chainId: '0x89',
    }
  },
}

function App() 
{
    const [state, updateState] = React.useState();

    const [mintValue, setMintValue] = useState(1);

    const [showModal, setShowModal] = useState(true);
    const [activeDiv, setActiveDiv] = useState("div1");
  
    const divOneRef = useRef(null);
    const divTwoRef = useRef(null);
    const wrapperRef = useRef(null);
    const footerRef = useRef(null);

    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions // required
    });

    const [connectedAddress, setConnectedAddress] = useState(null);
    const [balanceInEth, setBalanceInEth] = useState(null)
    const [web3Provider, setWeb3Provider] = useState(null);
    const [addressSigner, setAddressSigner] = useState(null);

    const [paused, setPaused] = useState(true);
    const [mintState, setMintState] = useState(0);
    const [nowMinting, setNowMinting] = useState(false);
    const [currentSupply, setCurrentSupply] = useState(null);
    const [isDiscounted, setIsDiscounted] = useState(null);
    const [isWhitelisted, setIsWhitelisted] = useState(null);

    const whitelistCost = 22000000000000000000;
    const publicCost = 22000000000000000000;
    const displayCost = 22;
    const publicDisplayCost = 22;

    const [functionError, setFunctionError] = useState(null);
    const [userRefCode, setUserRefCode] = useState(null);

    const toggleDiv = () => {
        setActiveDiv(activeDiv === "div1" ? "div2" : "div1");
    };

    const resetConnection = () => {
      web3Modal.clearCachedProvider();
      setWeb3Provider(null);
      setAddressSigner(null);
      setIsDiscounted(null);
      setIsWhitelisted(null);

      toggleDiv();

      //window.localStorage.clear();
      //window.location.reload();
    }

    const customNetwork = {
      chainId: '0x89',
      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: ['https://polygon-mainnet.infura.io'],
      blockExplorerUrls: ['https://polygonscan.com/'],
    };

    async function connectWallet()
    {
      console.log("CONNECTING");

      try 
      {
        const ethersProvider = ethers.getDefaultProvider('https://polygon-mainnet.infura.io/v3/2e6f51d534124b15924814f30cdf6a11');

        let web3ModalInstance = await web3Modal.connect();
        let web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);

        if (web3ModalProvider.provider.isWalletConnect) 
        {
          setConnectedAddress(web3ModalProvider.provider.accounts[0]);
        } 
        else
        {
          setConnectedAddress(web3ModalProvider.provider.selectedAddress);
        }

        const addPolygonMainnet = async () => {
          try {
            await web3ModalProvider.send('wallet_addEthereumChain', [
              {
                chainId: '0x89', // Chain ID for Polygon Mainnet
                chainName: 'Polygon Mainnet',
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'MATIC',
                  decimals: 18,
                },
                rpcUrls: ['https://polygon-rpc.com'], // RPC endpoint for Polygon Mainnet
                blockExplorerUrls: ['https://polygonscan.com'], // Block explorer URL for Polygon Mainnet
              },
            ]);
            console.log('Polygon Mainnet added to Web3 provider');
          } 
          catch (error) 
          {
            console.error('Failed to add Polygon Mainnet to Web3 provider:', error);
          }
        };

        if (web3ModalProvider.provider.chainId != '0x89')
        {
          await addPolygonMainnet();
        }
          
        let signer = web3ModalProvider.getSigner();

        const contractWithWallet = contract.connect(signer);
        try 
        {
          let currentSupply = await contractWithWallet.getTotalSupply();
          let paused = await contractWithWallet.getPausedState();
          let mintState = await contractWithWallet.getWhitelistState();
  
          setCurrentSupply(currentSupply.toString());
          setPaused(paused);
          setMintState(mintState);
        }
        catch (error)
        {
          console.log("CCalls Error during connect: " + error);
        }
        
        setWeb3Provider(web3ModalProvider);
        setAddressSigner(signer);

        let walletLink = document.getElementById("walletLink");
        walletLink.href = "https://polygonscan.com/address/" + web3ModalProvider.provider.selectedAddress;

        let contractLink = document.getElementById("contractLink");
        contractLink.href = "https://polygonscan.com/address/" + contractAddress;

        ethersProvider.getBalance(web3ModalProvider.provider.selectedAddress).then((balance) => {
          const remainder = balance.mod(1e13);
          setBalanceInEth(ethers.utils.formatEther(balance.sub(remainder)));
          console.log(`balance: ${ethers.utils.formatEther(balance.sub(remainder))} ETH`)
         })
        
        setTimeout(() => {

          const indexWL = whitelistAddresses.findIndex(element => {
            return element.toLowerCase() === web3ModalProvider.provider.selectedAddress.toLowerCase();
          });

          if (indexWL >= 0)
          {
            setIsWhitelisted(true);
          }
          else
            setIsWhitelisted(false);
        }, 0);

      }
      catch(error)
      {
        console.error(error);
      }
    }


    const contractAddress = '0x53A0F285f2baE93a3e473f07983dC3b1b9266d88';
    
    const ABI = [
      "function setPaused(bool x)",
      "function whitelistMint(bytes32[] x, uint256 y) payable",
      "function publicMint(uint256 x, address y) payable",
      "function getTotalSupply() view returns (uint256)",
      "function getPausedState() view returns (bool)",
      "function getWhitelistState() view returns (uint256)"
    ];

    const contract = new ethers.Contract(contractAddress, ABI, addressSigner);

    const { MerkleTree } = require('merkletreejs');
    const keccak256 = require('keccak256');

    let whitelistAddresses = [
      '0xB0229D4d572112021720CbE5A38F5Dc41f74E495'
];

    const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});
    const rootHash = merkleTree.getRoot();

    //console.log("WL: " + rootHash.toString("Hex"));

    function processAddress(ethAddress) {
      // Ensure the input address is a valid Ethereum address
      if (!/^0x[0-9a-fA-F]{40}$/.test(ethAddress)) {
        return null; // Invalid address
      }
    
      // Remove "0x" prefix
      const addressWithoutPrefix = ethAddress.slice(2);
    
      // Extract the 3rd to 6th digits
      const middleDigits = addressWithoutPrefix.slice(0, 4);
    
      // Extract the last 4 digits
      const lastFourDigits = addressWithoutPrefix.slice(-4);
    
      // Combine the extracted parts to get the result
      const result = middleDigits + lastFourDigits;
    
      return result;
    }

    let userRefCodeHasBeenRead = false;

    async function checkData()
    {
      if (connectedAddress != null)
      {
        let tempPaused = null;
        let tempSupply = null;
        let tempState = null;

        let tempRefCode = processAddress(connectedAddress);

        let accountRef = ref(db, `/refaddresses/${tempRefCode}`);

        if (!userRefCodeHasBeenRead) 
        {
          onValue(accountRef, (snapshot) => 
          {
            const retUserRefCode = snapshot.val();
            if (retUserRefCode != null) 
            {
              setUserRefCode(tempRefCode);
              console.log("RefCode Set!");
            } 
            else 
            {
              console.log("Created new ref code");
              set(ref(db, `/refaddresses/${tempRefCode}`), {
                Address: connectedAddress
              }, { merge: true });
            }
            userRefCodeHasBeenRead = true; // Set the flag to prevent further reads
          });
        }
        
        try
        {
          const contractWithWallet = contract.connect(addressSigner);

          if (contractWithWallet != null)
          {
            tempPaused = await contractWithWallet.getPausedState();
            tempSupply = await contractWithWallet.getTotalSupply();
            tempState = await contractWithWallet.getWhitelistState();
          }
          else
          {
            console.log("Contract With Wallet is Empty");
          }
        }
        catch (error)
        {
          console.log("RPC ERROR: " + error);
        }    

        setPaused(tempPaused);
        setCurrentSupply(tempSupply);
        setMintState(tempState);
      }
    }

    useEffect(() => {
      if (isWhitelisted !== null)
      {
        toggleDiv();
        checkData();
      }

    }, [isWhitelisted]);

    useEffect(() => {
    }, [paused, currentSupply]);

    useEffect(() => {
      const interval = setInterval(() => {
        checkData();
      }, 10000);
    
      return () => clearInterval(interval);
    }, [addressSigner]);

    async function LeMint(amount)
    {
      try {
        const ethersProvider = ethers.getDefaultProvider('https://polygon-mainnet.infura.io/v3/2e6f51d534124b15924814f30cdf6a11');

        setFunctionError(null);

        setNowMinting(true);
        const leaf = keccak256(connectedAddress);

        const userLeaf = merkleTree.getHexProof(leaf);
  
        const contractWithWallet = contract.connect(addressSigner);
        var tx = null;

        const cost = String(parseInt(whitelistCost * amount));
        tx = await contractWithWallet.whitelistMint(userLeaf, amount, { value: cost, gasLimit: String(200000) });
        await tx.wait();
      
        checkData();
        setNowMinting(false);

        setFunctionError("Mint Successful!");

        ethersProvider.getBalance(connectedAddress).then((balance) => {
          const remainder = balance.mod(1e13);
          setBalanceInEth(ethers.utils.formatEther(balance.sub(remainder)));
         })
      }
      catch(err) {
        console.log(err.code);
        setFunctionError("Mint Failed: " + err.code);
        checkData();
        setNowMinting(false);
      }
    }

    async function writeToDB(amount)
    {
      const unixTimestamp = Math.floor(new Date().getTime() / 1000); // Get current UNIX timestamp
      const dateTimeUTC = new Date().toUTCString(); // Get current datetime in UTC
    
      // Get the transaction hash from the receipt
      const txHash = 'TestTransactionHash';

      // Create a Polygonscan link for the transaction
      const polygonscanLink = `https://polygonscan.com/tx/${txHash}`;

      if (inputValue != "")
      {

        let refCoderef = ref(db, `/refaddresses/${inputValue.toString()}`);
        let accountRef = ref(db, `/refs/${unixTimestamp}`);

        let referrerAddy = "";

        try 
        {
          onValue(refCoderef, (snapshot) => 
          {
            const retReferrerAddy = snapshot.val();
            if (retReferrerAddy != null) 
            {
              referrerAddy = retReferrerAddy.Address;
              console.log(retReferrerAddy);
              console.log(referrerAddy);

              set(accountRef, 
              {
                Date: dateTimeUTC,
                Referrer: referrerAddy.toString(),
                Minter: connectedAddress,
                Amount: amount,
                Polyscan: polygonscanLink
              }, { merge: true });
            } 
            else 
            {
              setFunctionError("Wrong Ref Code!");
            }
          });
        } 
        catch (error) 
        {
          console.error("Error writing to ref database:", error);
        }
      }
    }

    async function LePublicMint(amount)
    {
      try {
        const ethersProvider = ethers.getDefaultProvider('https://polygon-mainnet.infura.io/v3/2e6f51d534124b15924814f30cdf6a11');

        const unixTimestamp = Math.floor(new Date().getTime() / 1000); // Get current UNIX timestamp
        const dateTimeUTC = new Date().toUTCString(); // Get current datetime in UTC

        let referrerAddy = "";

        if (inputValue != "")
        {
          let refCoderef = ref(db, `/refaddresses/${inputValue.toString()}`);
          try 
          {
            const snapshot = await get(refCoderef);
            const retReferrerAddy = snapshot.val();

            if (retReferrerAddy != null) 
            {
              
            } 
            else 
            {
              setFunctionError("Wrong Ref Code!");
              return;
            }
          } 
          catch (error) 
          {
            setFunctionError("Error reading from ref database:", error);
            return;
          }
        }

        setFunctionError(null);

        setNowMinting(true);
        const cost = String(parseInt(publicCost * amount));
        const contractWithWallet = contract.connect(addressSigner);
        var tx = null;

        tx = await contractWithWallet.publicMint(amount, connectedAddress, { value: cost, gasLimit: String(1000000)});
        const receipt = await tx.wait();

        // Get the transaction hash from the receipt
        const txHash = receipt.transactionHash;

        // Create a Polygonscan link for the transaction
        const polygonscanLink = `https://polygonscan.com/tx/${txHash}`;

        if (inputValue != "")
        {
          let refCoderef = ref(db, `/refaddresses/${inputValue.toString()}`);
          let accountRef = ref(db, `/refs/${unixTimestamp}`);

          try 
          {
            onValue(refCoderef, (snapshot) => 
            {
              const retReferrerAddy = snapshot.val();
              if (retReferrerAddy != null) 
              {
                referrerAddy = retReferrerAddy.Address;

                set(accountRef, 
                {
                  Date: dateTimeUTC,
                  Referrer: referrerAddy.toString(),
                  Minter: connectedAddress,
                  Amount: amount,
                  Polyscan: polygonscanLink
                }, { merge: true });
              } 
              else 
              {
                setFunctionError("Wrong Ref Code!");
              }
            });
          } 
          catch (error) 
          {
            console.error("Error writing to ref database:", error);
          }
        }

        checkData();

        setNowMinting(false);

        setFunctionError("Mint Successful!");

        ethersProvider.getBalance(connectedAddress).then((balance) => {
          const remainder = balance.mod(1e13);
          setBalanceInEth(ethers.utils.formatEther(balance.sub(remainder)));
         })

      } catch(err) {
        setFunctionError("Mint Failed: " + err.code);
        console.log(err.code);
        checkData();
        setNowMinting(false);
      }
    }

    useEffect(() => {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(true);
      }, 1000);
    }, []);

    const [inputValue, setInputValue] = useState("");

    return (
      <div className={`page-wrap`}>
        <Container className='mint-container' fluid>

          <div className='modal-container'>
            <div className='modal' id='modal-window'>
              <div ref={wrapperRef} className='modal-content'>
                <Row>
                  <img
                    src={centerImage}
                    className={"modal-center-image"}
                    alt='mySvgImage'
                  />
                </Row>
                <Row>
                  <span className='ca-header'>Careless Aliens Mint</span>
                </Row>

                <div
                  id='div1'
                  className={activeDiv === "div1" ? "div1 show" : "div1 hide"}
                  ref={divOneRef}
                >
                  <br/>

                  <Row className='margin-block-20'>
                    <button className='connect-button' onClick={connectWallet}>
                      CONNECT YOUR WALLET
                    </button>
                  </Row>
                  <br/>
                  
                  <Row className='margin-block-20'>
                    <span className='metamask-notice'>
                      MOBILE? 
                      <br/>Visit the website inside your crypto wallet application (MM/Phantom etc)!
                      <br/>The mint is happening on the Polygon Mainnet!
                    </span>
                  </Row>
                  <br/>
                  <br/>
                </div>
  
                <div
                  id='div2'
                  ref={divTwoRef}
                  className={activeDiv === "div2" ? "div2 show" : "div2 hide"}
                >
                  <Row className="text-box">
                    <a id="contractLink" target="_blank">
                    <span className='text-span'>{"CONTRACT : " + contractAddress.toUpperCase()}</span>
                    </a>
                  </Row>

                  <Row className="margin-bottom-20">
                    <span className='gray-text'> total supply</span>
                  </Row>

                  <Row className="margin-bottom-20">
                    <span className='white-text'> {currentSupply ? currentSupply + " / 10000" : ""}</span>
                  </Row>                

                  <Row className="text-box">
                    <a id="walletLink" target="_blank">
                    <span className='text-span'>{web3Provider ? "WALLET : " + connectedAddress.toUpperCase() : "Loading..."}</span>
                    </a>
                  </Row>

                  <Row className="ref-box">
                    <span className='ref-span'>{userRefCode ? "Your Ref Code: " + userRefCode : "Loading..."}</span>
                  </Row>

                  <Row className='margin-bottom-20'>
                    <span className='gray-text'> balance </span>
                  </Row>                      

                  <Row className='margin-bottom-20'>
                    <span className='white-text'>
                    {balanceInEth ? balanceInEth : "Loading..."}
                    </span>
                    <img src={matic} alt='ethereumIcon' width='18px' height='18px' />
                  </Row>
  
                  <div className='button-row-content'>

                  <button
                      className='button-decrease-increase'
                      onClick={() => {
                        mintValue - 10 >= 1 && setMintValue(mintValue - 10);
                      }}
                    >
                      -10 
                    </button>

                    <button
                      className='button-decrease-increase'
                      onClick={() => {
                        mintValue !== 1 && setMintValue(mintValue - 1);
                      }}
                    >
                      -
                    </button>

                    <span className='mint-value'>{mintValue}</span>

                    <button
                      className='button-decrease-increase'
                      onClick={() => {
                        mintValue + 1 <= 25 && setMintValue(mintValue + 1)}}
                    >
                      +
                    </button>

                    <button
                      className='button-decrease-increase'
                      onClick={() => {
                        mintValue + 10 <= 25 && setMintValue(mintValue + 10)}}
                    >
                      +10
                    </button>

                  </div>

                  <Row className='margin-bottom-20'>
                    <span className='gray-text'>
                      Cost
                    </span>
                  </Row>
  
                  <Row className='margin-bottom-20'>
                    <span className='text-eth-value'>{ mintState == 0 ? (displayCost * mintValue).toFixed(2) : (publicDisplayCost * mintValue).toFixed(2)}</span>
                    <img src={matic} alt='ethereumIcon' width='18px' height='18px'/>
                    <span className='text-span'>+ Gas Fee</span>
                  </Row>    

              <div className="yellow-text">{functionError}</div>
                {paused ? <span className= 'info-box'><p className='info-text'>MINTING HASN'T STARTED YET</p></span> : 

                nowMinting ? <span className= 'info-box'><div className="spinner"></div></span>
                :
                mintState == 0 ?  
                  <Row className='margin-bottom-20'>
                    <button
                      className='app-button'
                      onClick={() => LeMint(mintValue)}
                    >
                      WHITELIST MINT
                    </button>
                  </Row>
                : 
                <div>
                  <Row>
                    <input
                    className="refInput"
                    type="text"
                    placeholder="REF CODE"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  </Row>
                  
                  <Row className='margin-bottom-20'>
                    <button
                      className='app-button'
                      onClick={() => LePublicMint(mintValue, connectedAddress)}
                    >
                      PUBLIC MINT
                    </button>
                  </Row>
                  
                  </div>
                }
                </div>
              </div>
  
              <div ref={footerRef} className='modal-footer'>
                <div className='modal-footer-content'>
                  <a href="https://discord.gg/UpuQpAcxUQ" target="_blank" rel="noreferrer" className="link">
                    <img src={discordIcon} alt='discordIcon' />
                  </a>
                  <a href="https://twitter.com/TheNubisProject" target="_blank" rel="noreferrer" className="link">
                    <img src={twitterIcon} alt='twitterIcon' />
                  </a>
                  <a href="https://www.opensea.io" target="_blank" rel="noreferrer" className="link">
                    <img src={boatIcon} alt='boatIcon' />
                  </a>
                </div>
                <p className='yellow-text'>99% of errors can be solved by reloading the page!</p>
                <p className='footer-text'>© 2023 Careless Alien Club. All Rights Reserved.</p>

              </div>
            </div>
          </div>
        </Container>
      </div>
  );
}

export default App;
