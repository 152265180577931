import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import "firebase/database";
import { getAuth } from "firebase/auth";
require('firebase/auth')

const firebaseConfig = {
    apiKey: "AIzaSyDufwuKclmvtrP3ZM0PhGaVNS8dP02pLmA",
    authDomain: "nubisrefs.firebaseapp.com",
    databaseURL: "https://nubisrefs-default-rtdb.firebaseio.com",
    projectId: "nubisrefs",
    storageBucket: "nubisrefs.appspot.com",
    messagingSenderId: "846860770251",
    appId: "1:846860770251:web:fc45f4ec8c2f386106f44e"
  };

  const app = initializeApp(firebaseConfig);

  export const auth = getAuth();
  export const db = getDatabase(app);